import request from '@/utils/request'

// 从总数据表中检索
export function getStaffInfos(params) {
  return request({
    url: '/api/staffInfo/noPage',
    method: 'get',
    params
  })
}

export function getXgStaffInfoNoPage(params) {
  return request({
    url: '/api/xg/staffInfo/noPage',
    method: 'get',
    params
  })
}

export function getStaffInfosSmallNoPage(params) {
  return request({
    url: '/api/staffInfo/noPage/small',
    method: 'get',
    params
  })
}


export function add(data) {
  return request({
    url: 'api/xg/staffInfo',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/xg/staffInfo',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/xg/staffInfo',
    method: 'put',
    data
  })
}

export function changeStatus(data) {
  return request({
    url: 'api/xg/staffInfo/changeStatus',
    method: 'post',
    data
  })
}


export function getStaffTree(params) {
  return request({
    url: '/api/xg/staffInfo/tree',
    method: 'get',
    params
  })
}


export default { add, edit, del, changeStatus, getStaffInfos, getXgStaffInfoNoPage, getStaffTree }
