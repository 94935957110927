import request from '@/utils/request'

export function add(data) {
    return request({
        url: 'api/evaluation/config',
        method: 'post',
        data
    })
}

export function del(ids) {
    return request({
        url: 'api/evaluation/config/',
        method: 'delete',
        data: ids
    })
}

export function edit(data) {
    return request({
        url: 'api/evaluation/config',
        method: 'put',
        data
    })
}

export function getTrees(type) {
    return request({
        url: 'api/evaluation/config/tree/' + type,
        method: 'get',
    })
}
export function getConfigNoPage(params) {
    return request({
        url: 'api/evaluation/config/noPage',
        method: 'get',
        params
    })
}

export function getAuditUserPage() {
    return request({
        url: 'api/evaluation/config/user',
        method: 'get',

    })
}
export default { add, edit, del, getTrees, getConfigNoPage, getAuditUserPage }
